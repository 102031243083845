import { useEffect, useMemo, useState } from 'react';
import { useParams, useLocation } from 'react-router-dom';
import { SoundFontPlayer } from '@magenta/music';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { Trans as Translate, useTranslation } from 'react-i18next';
import { strofeApi } from '../../api/strofeApi';
import useEventCallback from '../../hooks/useEventCallback';

import Form from 'react-bootstrap/Form';
import Toast from 'react-bootstrap/Toast';
import Checkbox from '../../layout/Checkbox';
import Slider, { Range } from 'rc-slider';

import { usersSelectors } from '../../store/usersSlice';
import { STYLES } from '../CreateSong/CreateSong';
import COMPOSER_SCALES from '../../utils/rails_generated/composer_scales';
import { STATUS_TRANSLATION } from '../NoteSequences/NoteSequences';
import { availablePrograms } from '../../utils/InstrumentPrograms';

import NavigationHeader from '../NavigationHeader';
import PianoRoll from './PianoRoll';
import SelectStyleModal from './SelectStyleModal';
import CropNoteModal from './CropNoteModal';
import SaveSequenceModal from './SaveSequenceModal';
import { DRUMS } from '../../utils/rails_generated/drums';

import PlayIconV2 from '../../icons/PlayIconV2';
import PencilIcon from '../../icons/PencilIcon';

import 'rc-slider/assets/index.css';
import './Composer.scss';

let _id_counter = 1;

export const generateUniqueId = () => {
  return _id_counter++;
}

let sfPlayer = null;

const NOTES_COUNT = 12;
const NOTES_NAME = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];

const DIVISIONS = [4, 6, 5];

const DEFAULT_SCALE = 'ionian';
const DEFAULT_DIVISION = 4;
const DEFAULT_DURATION_BARS = 4;
const DEFAULT_QPM = 120;
const DEFAULT_SWING = 50;
const DEFAULT_VELOCITY = 80;

const REPEAT_SEQUENCE_COUNT = 10;

const EXTRA_OCTAVE = 1;
const COMPOSER_LOWEST_OCTAVE = 2;
const COMPOSER_HIGHEST_OCTAVE = 6 + EXTRA_OCTAVE;

const BASE_PITCH = NOTES_COUNT * (COMPOSER_HIGHEST_OCTAVE + 1);

const MIN_DURATION_BAR = 1;
const MAX_DURATION_BAR = 22;

const MIN_DYNAMIC_RANGE = 0;
const MAX_DYNAMIC_RANGE = 6;

const DEFAULT_STYLE = 'jazz';

// Since magentajs has constants.MIN/MAX_PITCH for drums, create a
// sequence including all drums and velocities available:
const drumsSequence = (() => {
  const notes = [];
  Object.values(DRUMS).forEach(pitch => {
    [15, 31, 47, 63, 79, 95, 111, 127].forEach(velocity => {
      notes.push({
        pitch,
        startTime: 0,
        endTime: 1,
        velocity,
        program: 0,
        isDrum: true,
      })
    });
  });

  return { totalTime: 1, notes };
})();

export default function Composer() {

  const { id } = useParams();
  const { t } = useTranslation();
  const location = useLocation();

  const localSoundfonts = useMemo(() => new URLSearchParams(location.search).get('local') === 'true', [location]);
  
  const initialDrums = useMemo(() => new URLSearchParams(location.search).get('drums') === 'true', [location]);

  const [loadStatus, setLoadStatus] = useState('initial-lode');
  const [loadedFonts, setLoadedFonts] = useState(false);
  const [sequence, setSequence] = useState(null);
  const [status, setStatus] = useState('draft');
  const [scale, setScale] = useState(DEFAULT_SCALE);
  const [root, setRoot] = useState(0); // C
  const [division, setDivision] = useState(DEFAULT_DIVISION);
  const [duration, setDuration] = useState(DEFAULT_DURATION_BARS);
  const [tempDuration, setTempDuration] = useState(duration);
  const [qpm, setQpm] = useState(DEFAULT_QPM);
  const [swing, setSwing] = useState(DEFAULT_SWING);
  const [addChord, setAddChord] = useState(false);
  const [isPlaying, setIsPlaying] = useState(false);
  const [noteVelocity, setNoteVelocity] = useState(DEFAULT_VELOCITY);
  const [tempVelocity, setTempVelocity] = useState(noteVelocity);
  const [noteProbability, setNoteProbability] = useState(100);
  const [tempProbability, setTempProbability] = useState(noteProbability);
  const [songTitle, setSongTitle] = useState(t('New Composition'));
  const [overTheBar, setOverTheBar] = useState(false);
  const [pentatonic, setPentatonic] = useState(false);
  const [notes, setNotes] = useState({});
  const [chords, setChords] = useState({});
  const [sequenceId, setSequenceId] = useState(id);
  const [styles, setStyles] = useState([DEFAULT_STYLE]);
  const [playingNotes, setPlayingNotes] = useState({});
  const [program, setProgram] = useState(0);
  const [dynamicRange, setDynamicRange] = useState([MIN_DYNAMIC_RANGE, MAX_DYNAMIC_RANGE]);
  const [drumsMode, setDrumsMode] = useState(initialDrums);

  const [showStyleSelect, setShowStyleSelect] = useState(false);
  const [showCropNotes, setShowCropNotes] = useState(false);
  const [showSaveSequence, setShowSaveSequence] = useState(false);
  const [toastSequenceSaved, setToastSequenceSaved] = useState(false);

  const currentUser = useSelector(usersSelectors.getCurrentUser);

  const addHighlightedNote = useEventCallback(note => {
    setPlayingNotes(notes => ({ ...notes, [note._id]: note }));

    if (!note._fullDuration) {
      setTimeout(() => clearNote(note._id), (note.endTime - note.startTime) * 1000);
    }
  });

  const clearNote = useEventCallback(noteId => {
    setPlayingNotes(notes => {
      const newNotes = JSON.parse(JSON.stringify(notes));
      delete newNotes[noteId];
      
      return newNotes;
    });
  })

  useEffect(() => {
    
    if (loadStatus === 'ready') {

      const localUrl = process.env.NODE_ENV === 'development' ? '/player' : 'https://sf.strofe.com';
      const soundFontURL = (drumsMode || localSoundfonts) ? localUrl : 'https://storage.googleapis.com/magentadata/js/soundfonts/sgm_plus';
      
      sfPlayer = new SoundFontPlayer(soundFontURL);

      sfPlayer.callbackObject = {
        run  : addHighlightedNote,
        stop : () => null,
      };

      if (drumsMode) {
        sfPlayer.loadSamples(drumsSequence).then(() => setLoadedFonts(true)).catch(error => {
          alert("Error loading samples. Close this tab or reload this page to try again.\n Error: " + error);
        });
      }
      else {
        sfPlayer.loadAllSamples(0, drumsMode).then(() => setLoadedFonts(true)).catch(error => {
          alert("Error loading samples. Close this tab or reload this page to try again.\n Error: " + error);
        });
      }
      
      // On navigation or route change, stop the song if it's playing:
      return () => {
        if (isPlaying || sfPlayer?.isPlaying()) {
          sfPlayer?.stop();
        }
      }
    }
  }, [addHighlightedNote, loadStatus]);

  useEffect(() => {

    // TODO loading
    const loadSequence = async () => {
      setLoadStatus('loading-track');
      const { data } = await strofeApi.get(`/note_sequences/${id}`);

      setDrumsMode(data.category === 'drums');
      setSongTitle(data.title || t('New Composition'));
      setDuration(data.duration);
      setTempDuration(data.duration);
      setDynamicRange([data.dynamic_start, data.dynamic_end]);
      setOverTheBar(data.over_the_bar || false);
      setPentatonic(data.is_pentatonic || false);
      setStyles(data.styles?.length === 0 ? ['jazz'] : data.styles);
      setStatus(data.status);
      setDivision(data.subdivision === 3 ? 6 : data.subdivision);
      setProgram(data.program || 0);

      loadStrofeSequence(data);
      setLoadStatus('ready');

      console.log('data:', data);
    }

    if (id) {
      loadSequence();
    }
    else {
      setLoadStatus('ready');
    }

  }, [id]);

  const playNote = (pitch = 60) => {
    sfPlayer.playNoteDown({ pitch, velocity: noteVelocity, isDrum: drumsMode });
  }

  const updateSequence = sequence => {
    setSequence(sequence);
  }

  const notesToSequence = (newNotes, newChords) => {
    const sequenceNotes = [];
    let totalTime = 0;

    newNotes && Object.entries(newNotes).forEach(([pitchIndex, trackNote]) => {
      Object.entries(trackNote.singleNotes).forEach(([segmentIndex, singleNote]) => {

        // If the note is beyond the duration (ie: when changing the sequence length), skip it
        if (Math.ceil(singleNote.end / singleNote.division) > duration) {
          return;
        }

        // skip all notes that are not enabled (when pentatonic is on, it will also ignore non-pentatonic notes):
        if (!drumsMode && !noteEnabled((NOTES_COUNT - (pitchIndex % NOTES_COUNT)) % NOTES_COUNT)) {
          return;
        }

        totalTime = Math.max(singleNote.end * singleNote.division / 8 / 4, totalTime);

        let addedSwing = 0;

        if (swing !== 50 && singleNote.division === 4 && singleNote.start % 4 === 2) {
          addedSwing = 0.5 * (swing - 50) / 100;
        }
        
        // If the note takes the full duration, it will stay highlighted for the whole track:
        // TODO -> using <= and >= instead of === because there are no guards on note durations:
        const _fullDuration = singleNote.start <= 0 && (singleNote.end/singleNote.division) >= duration;

        const pitch = drumsMode ? trackNote.pitch : BASE_PITCH - pitchIndex;
        
        // _probability added into the note sequence
        sequenceNotes.push({
          pitch,
          startTime    : (singleNote.start * 2 / singleNote.division / 4 + addedSwing) * (DEFAULT_QPM / qpm),
          endTime      : singleNote.end * 2 / singleNote.division / 4 * (DEFAULT_QPM / qpm),
          velocity     : singleNote.velocity,
          _probability : singleNote.probability,
          _id          : singleNote._id,
          _fullDuration,
          program,
          isDrum: drumsMode,
        });
      });
    });

    newChords && Object.entries(newChords).forEach(([pitchIndex, trackChords]) => {
      trackChords.forEach(chord => {

        // If the note is beyond the duration (ie: when changing the sequence length), skip it
        if (Math.ceil(chord.end / chord.division) > duration) {
          return;
        }

        totalTime = Math.max((chord.end - 1) * chord.division / 8 / 4, totalTime);

        let addedSwing = 0;

        // TODO -> swing needs to update the note sequence array
        if (swing !== 50 && chord.division === 4 && chord.start % 4 === 2) {
          addedSwing = 0.5 * (swing - 50) / 100;
        }

        const startTime = (chord.start * 2 / chord.division / 4 + addedSwing) * (DEFAULT_QPM / qpm);
        const endTime = (chord.end * 2 / chord.division / 4) * (DEFAULT_QPM / qpm);

        // If the note takes the full duration, it will stay highlighted for the whole track:
        // TODO -> using <= and >= instead of === because there are no guards on note durations:
        const _fullDuration = chord.start <= 0 && (chord.end/chord.division) >= duration;

        sequenceNotes.push({
          pitch        : BASE_PITCH - (pitchIndex * NOTES_COUNT - root + NOTES_COUNT),
          velocity     : chord.velocity,
          _chord_index : 0,
          _probability : chord.probability,
          _id          : chord._id,
          _fullDuration,
          program,
          startTime,
          endTime,
        });

        sequenceNotes.push({
          pitch        : BASE_PITCH - (pitchIndex * NOTES_COUNT - root + NOTES_COUNT - COMPOSER_SCALES[scale].full[2]),
          velocity     : chord.velocity,
          _chord_index : 1,
          program,
          startTime,
          endTime,
        });

        sequenceNotes.push({
          pitch        : BASE_PITCH - (pitchIndex * NOTES_COUNT - root + NOTES_COUNT - COMPOSER_SCALES[scale].full[4]),
          velocity     : 70,
          _chord_index : 2,
          program,
          startTime,
          endTime,
        });

      });
    });

    updateSequence({ totalTime, notes: sequenceNotes });

    return { totalTime, notes: sequenceNotes };
  }

  const onNoteSelect = note => {
    setTempVelocity(note.velocity);
    setTempProbability(note.probability);
  }

  const playSequence = () => {
    // update sequence to include changes from tempo, swing, etc.
    // !NOTE ignoring the sequence on the the state and using the own sequence:
    const sequence = notesToSequence(notes, chords);

    if (!sequence || isPlaying || sfPlayer.isPlaying()) {
      return;
    }

    const loopedSequence = JSON.parse(JSON.stringify(sequence));
    loopedSequence.notes = [];
    let sequenceDuration = duration / 2 * (DEFAULT_QPM / qpm);

    // extend the sequence COUNT-1 times:
    const notesLength = sequence.notes.length;

    const skipChords = [];

    for (let i = 0; i < notesLength; ++i) {
      for (let j = 0; j < REPEAT_SEQUENCE_COUNT; ++j) {

        const note = { ...sequence.notes[i] };
        const randomChance = Math.floor(Math.random() * 100) + 1;

        // skip notes that are out of the probability range (from 1 to 100)
        if ((note._chord_index === 0 || note._chord_index === undefined) && randomChance > note._probability) {
          // if it's a chord, the chord is skipped on next (i,j) iteration on the outer loop
          if (note._chord_index === 0) {
            skipChords.push({ i, j });
          }

          continue;
        }

        // j is the skipped chord, and i will indicate the chord index note (chords are always pushed as [i, i+1, i+2]):
        if (note._chord_index > 0 && skipChords.find(coord => coord.i + note._chord_index === i && coord.j === j)) {
          continue;
        }

        note.startTime += sequenceDuration * j;
        note.endTime += sequenceDuration * j;

        loopedSequence.notes[i + (notesLength * j)] = { ...note };
      }
    }

    sequenceDuration *= REPEAT_SEQUENCE_COUNT;

    loopedSequence.totalTime = sequenceDuration;
    console.log('loopedSequence:', loopedSequence)

    // sfPlayer.loadAllSamples(program).then(() => {
    sfPlayer.loadSamples(loopedSequence).then(() => {
      console.log('loaded program:', program);
      setIsPlaying(true);
      
      sfPlayer.start(loopedSequence).then(() => {
        setIsPlaying(false);
        setPlayingNotes({});
      })
    }).catch(error => {
      alert("Error loading samples. Close this tab or reload this page to try again.\n Error: " + error);
    });
  }

  const stopSequence = () => {
    sfPlayer?.stop();
    setIsPlaying(false);
    setPlayingNotes({});
  }

  const toggleSequence = () => {
    isPlaying ? stopSequence() : playSequence();
  }

  const onAfterChangeDuration = value => {
    // sometimes afterChange gets triggered when the slider loses focus, make sure that values are different:
    if (value === duration && value === tempDuration) {
      return;
    }

    // If any note or chord is on a bar that will get cropped, show a modal.
    // IE: end = 11 on divison 5 would be Math.ceil(11/5) = Math.ceil(2.2) = 3
    // so if the division were 4 or 3, it won't crop the note, but 2 would crop as 3 > 2
    // position <= duration makes sure that notes that were cropped before don't count as overflowing
    const noteOverflow = noteChord => {
      const position = Math.ceil(noteChord.end / noteChord.division);
      return position > value && position <= duration
    }
    
    const isCroppingNotes = duration > value && (
      Object.values(notes).some(note => note.singleNotes.some(noteOverflow)) || Object.values(chords).some(noteOverflow)
    );

    if (isCroppingNotes) {
      setShowCropNotes(true);
    }
    else {
      setTempDuration(value);
      setDuration(value);
    }
  }

  const onSubmitCropNotes = () => {
    setDuration(tempDuration);
    setShowCropNotes(false);
  }

  const onHideCropNotes = () => {
    setTempDuration(duration);
    setShowCropNotes(false);
  }

  const onAfterProbabilityChange = value => {
    value !== noteProbability && setNoteProbability(value);
  }

  const onAfterVelocityChange = value => {
    value !== noteVelocity && setNoteVelocity(value);
  }

  const confirmSaveSequence = () => {
    if (status === 'published') {
      setShowSaveSequence(true);
    }
    else {
      saveSequence();
    }
  }

  const saveSequence = async (setToDraft = false) => {
    // if the modal was open, hide it:
    setShowSaveSequence(false);

    const strofeSeqNotes = [];

    let pitch;

    Object.values(notes).forEach(track => track.singleNotes.forEach(note => {
      
      // If the note is beyond the duration (ie: when changing the sequence length), skip it
      if (Math.ceil(note.end / note.division) > duration) {
        return;
      }

      if (drumsMode) {
        pitch = track.pitch;
      }

      else {
        pitch = track.scaleIndex + 1;
      
        if (pentatonic) {
          const trackScale = COMPOSER_SCALES[scale];
          const fullNote = trackScale.full[track.scaleIndex];
          
          // when pentatonic is enabled, notes that are not in the pentatonic scale are not immediately
          // deleted to allow the user to toggle it on and off. If such not is not in the scale, do not save it:
          if (!trackScale.pentatonic.includes(fullNote)) {
            return;
          }
          
          pitch = trackScale.pentatonic.findIndex(n => n === fullNote) + 1;
        }
      }

      let position = note.start + 1;
      let numerator = note.end - note.start;
      let division = note.division;

      // when numerator, denominator and note start are divisible by 2 (IE: 2/4 or 2/6) they can be simplified to 1/2 or 1/3 respectively:
      if (numerator % 2 === 0 && division % 2 === 0 && note.start % 2 === 0) {
        numerator /= 2;
        division /= 2;
        position = (note.start / 2) + 1;
      }

      const strofeNote = [
        drumsMode ? 0 : track.octave,  /*  OCTAVE      */
        pitch,         /*  PITCH       */
        note.velocity, /*  VELOCITY    */
        numerator,     /*  NUMERATOR   */
        division,      /*  DENOMINATOR */
        position,      /*  POSITION    */
      ];

      note.probability !== 100 && strofeNote.push(note.probability) /* PROBABILITY */;

      strofeSeqNotes.push(strofeNote);
    }));

    Object.entries(chords).forEach(([trackIndex, track]) => track.forEach(chord => {

      // If the note is beyond the duration (ie: when changing the sequence length), skip it
      if (Math.ceil(chord.end / chord.division) > duration) {
        return;
      }
      
      const octave = COMPOSER_HIGHEST_OCTAVE - parseInt(trackIndex, 10);
      let position = chord.start + 1;
      let numerator = chord.end - chord.start;
      let division = chord.division;

      console.log('chord:', chord)

      // when numerator, denominator and note start are divisible by 2 (IE: 2/4 or 2/6) they can be simplified to 1/2 or 1/3 respectively:
      if (numerator % 2 === 0 && division % 2 === 0 && chord.start % 2 === 0) {
        numerator /= 2;
        division /= 2;
        position = (chord.start / 2) + 1;
      }

      const strofeNote = [
        octave,         /*  OCTAVE      */
        0,              /*  PITCH       */
        chord.velocity, /*  VELOCITY    */
        numerator,      /*  NUMERATOR   */
        division,       /*  DENOMINATOR */
        position,       /*  POSITION    */
      ];

      chord.probability !== 100 && strofeNote.push(chord.probability) /* PROBABILITY */;

      strofeSeqNotes.push(strofeNote);
    }));

    let instruments = undefined;

    if (drumsMode) {
      instruments = Object.values(notes).map(note => note.pitch);
    }

    const note_sequence = {
      // category <- already comes from the "create sequence" modal in Note Sequences:
      title: songTitle,
      duration,
      dynamic_start : dynamicRange[0],
      dynamic_end   : dynamicRange[1],
      over_the_bar  : overTheBar,
      is_pentatonic : pentatonic,
      //! relative_octave,
      //! bassline_to_bass_drum,
      // status, <- no hace falta mandarlo
      notes: JSON.stringify(strofeSeqNotes),
      qpm,
      subdivision: division === 6 ? 3 : division,
      styles,
      program,
      instruments,
    }
    
    // TODO -> add try/catch for error handling
    if (sequenceId) {

      if (setToDraft) {
        note_sequence.status = 'draft';
      }
        
      const { data } = await strofeApi.put(`/note_sequences/${sequenceId}`, { note_sequence });
      setStatus(data.status);
    }

    else {
      const { data } = await strofeApi.post(`/note_sequences/`, { note_sequence });
      setSequenceId(data.id);
      setStatus(data.status);
    }

    setToastSequenceSaved(true);
  }

  const changeScale = newScale => {  
    setScale(newScale);

    if (!Object.values(notes).length) {
      return;
    }

    let newNotes = {};

    for (let [pitchIndex, note] of Object.entries(notes)) {
      if (pentatonic) {
        // transfer the notes that are only pentatonic
        if (noteEnabled((NOTES_COUNT - (pitchIndex % NOTES_COUNT)) % NOTES_COUNT)) {
          const pentatonicIndex = COMPOSER_SCALES[scale].pentatonic.findIndex(v => v === COMPOSER_SCALES[scale].full[note.scaleIndex]);

          note.scaleIndex = COMPOSER_SCALES[newScale].full.findIndex(v => v === COMPOSER_SCALES[newScale].pentatonic[pentatonicIndex]);
          newNotes[NOTES_COUNT - COMPOSER_SCALES[newScale].pentatonic[pentatonicIndex] - root + ((COMPOSER_HIGHEST_OCTAVE - note.octave) * NOTES_COUNT)] = note;
        }
      }
      else {
        newNotes[NOTES_COUNT - COMPOSER_SCALES[newScale].full[note.scaleIndex] - root + ((COMPOSER_HIGHEST_OCTAVE - note.octave) * NOTES_COUNT)] = note;
      }
    }

    // deep clone since previous operation creates shallow references:
    newNotes = JSON.parse(JSON.stringify(newNotes));
    setNotes(newNotes);
  }

  // TODO -> these are duplicated:
  const updateNotes = n => {
    const newNotes = JSON.parse(JSON.stringify(n));
    setNotes(newNotes);
    notesToSequence(newNotes, chords);
  }

  const updateChords = c => {
    const newChords = JSON.parse(JSON.stringify(c));
    setChords(newChords);
    notesToSequence(notes, newChords);
  }

  const noteEnabled = noteIndex => {
    const editorScale = COMPOSER_SCALES[scale][pentatonic ? 'pentatonic' : 'full'];
    return editorScale.findIndex(scaleIndex => (scaleIndex + root) % NOTES_COUNT === noteIndex) !== -1;
  }
  // TODO TODO TODO - END

  const onSubmitStyles = styles => {
    setStyles(styles);
    setShowStyleSelect(false);
  }
  
  const loadStrofeSequence = async strofeSeq => {
    const OCTAVE = 0, PITCH = 1, VELOCITY = 2, NUMERATOR = 3, DENOMINATOR = 4, POSITION = 5, PROBABILITY = 6;

    const newNotes = {};
    const newChords = {};

    const isDrums = strofeSeq.category === 'drums';

    if (isDrums) {
      strofeSeq.instruments?.map((pitch, index) => {
        newNotes[index] = { pitch, singleNotes: [] }
      });
    }

    strofeSeq.notes?.forEach(strofeNote => {
      let division;

      switch (strofeNote[DENOMINATOR]) {
        case 3:
        case 6:
          division = 6;
          break;

        case 5:
          division = 5;
          break;

        default:
          division = 4;
      }

      const velocity = strofeNote[VELOCITY];
      const start = division / strofeNote[DENOMINATOR] * (strofeNote[POSITION] - 1);
      const end = start + (division / strofeNote[DENOMINATOR] * strofeNote[NUMERATOR]);
      const probability = strofeNote[PROBABILITY] || 100;
      const _id = generateUniqueId();
      const seqPentatonic = strofeSeq.is_pentatonic;

      if (strofeNote[PITCH] !== 0) {
        const trackIndex = NOTES_COUNT - COMPOSER_SCALES[scale][seqPentatonic ? 'pentatonic' : 'full'][strofeNote[PITCH] - 1] - root + ((COMPOSER_HIGHEST_OCTAVE - strofeNote[OCTAVE]) * NOTES_COUNT)

        let scaleIndex = strofeNote[PITCH] - 1;
        
        // scale index is based only on the full scale, if the StrofeSeq is pentatonic, find the equivalent full index:
        if (seqPentatonic) {
          const selectedScale = COMPOSER_SCALES[scale];
          const pentatonicNote = selectedScale.pentatonic[strofeNote[PITCH] - 1];

          scaleIndex = selectedScale.full.findIndex(n => n === pentatonicNote);
        }

        if (isDrums) {
          const length = Object.entries(newNotes).length;
          const pitch = strofeNote[PITCH];

          let trackIndex = Object.values(newNotes).findIndex(note => note.pitch === pitch);

          newNotes[trackIndex].singleNotes.push({ division, velocity, start, end, probability, _id });
        }
        else {
          if (!newNotes[trackIndex]) {
            newNotes[trackIndex] = {
              scaleIndex,
              octave      : strofeNote[OCTAVE],
              singleNotes : [],
            };
          }

          newNotes[trackIndex].singleNotes.push({ division, velocity, start, end, probability, _id });
        }
      }
      
      // chord (pitch = 0)
      else {
        const octaveIndex = COMPOSER_HIGHEST_OCTAVE - strofeNote[OCTAVE];

        if (!newChords[octaveIndex]) {
          newChords[octaveIndex] = [];
        }

        newChords[octaveIndex].push({ division, velocity, start, end, probability, _id });
      }
    });
    
    updateNotes(newNotes);
    updateChords(newChords);

    notesToSequence(newNotes, newChords);
  }

  if (!loadedFonts) {
    return (
      <div className='__composer'>
        <NavigationHeader />
        <div className='loading'>LOADING SOUND FONTS...</div>
      </div>
    );
  }

  const renderStyleIcon = () => {
    const Icon = STYLES.find(style => style.id === styles[0])?.icon;
    return Icon ? <Icon /> : null;
  }

  const renderStyleLabel = () => {
    const firstStyle = STYLES.find(style => style.id === styles[0]);

    if (styles.length === 0) {
      return null;
    }
    else if (styles.length === 1) {
      return <Translate>{ firstStyle.phrase }</Translate>
    }

    return <Translate i18nKey='style-plus-number' values={{ style: t(firstStyle.phrase), number: styles.length - 1 }} />
  }

  const changeStatus = async e => {
    setStatus(e.target.value);
    await strofeApi.put(`/note_sequences/${sequenceId}`, { note_sequence: { status: e.target.value } });

    // TODO -> error handling
    setToastSequenceSaved(true);
  }

  const availableStatus = () => {
    const statuses = ['draft', 'ready'];
    (currentUser.super_user || currentUser.composer) && statuses.push('review');
    (currentUser.super_user || status === 'published') && statuses.push('published');

    return statuses;
  }

  return (
    <div className='__composer'>
      <NavigationHeader />

      <div className='main-editor'>
        <div className='title-edit'>
          <div className='song-title-container'>
            <Form.Control className='song-title' value={songTitle} onChange={e => setSongTitle(e.target.value)} />
            <PencilIcon />
          </div>

          { sequenceId && (
            <Form.Control as='select' className={classNames('sequence-status', status)} value={status} onChange={changeStatus}>
              { availableStatus().map(s => <option key={s} value={s}>{ t(STATUS_TRANSLATION[s]) }</option> )}
            </Form.Control>
          )}
        </div>

        <div className='style-controls'>

          <div>
            <div className='style-select' onClick={() => setShowStyleSelect(true)}>
              { renderStyleIcon() }
              <div className='style-name'>{ renderStyleLabel() }</div>
            </div>

            <div className='save-container'>
              <button onClick={confirmSaveSequence}><Translate>Save</Translate></button>
            </div>
          </div>

          <div className='controls'>
            <div className='main-controls'>
              <div className='scale-root'>
                <div className='selector'>
                  <div className='label'>Duration</div>
                    <div className='duration-slider'>
                      <Slider min={MIN_DURATION_BAR} max={MAX_DURATION_BAR} onChange={value => setTempDuration(value)} value={tempDuration} onAfterChange={onAfterChangeDuration} />
                    <div className='amount'>{ tempDuration }</div>
                  </div>
                    
                  { !drumsMode && (
                    <>
                      <div className='label'>Root</div>
                      <Form.Control as='select' className='option root' value={root} onChange={e => setRoot(parseInt(e.target.value))}>
                        { NOTES_NAME.map((note, index) => (
                          <option key={note} value={index}>{ note }</option>
                        ))}
                      </Form.Control>

                      <div className='option pentatonic'>
                        <Checkbox checked={pentatonic} onChange={e => setPentatonic(e.target.checked)} togglePosition='end'>Pentatonic</Checkbox>
                      </div>
                    </>
                  )}

                </div>

                <div className='selector'>
                  <div className='label range'>Dynamic Range</div>
                  <div className='dynamic-range'>
                    <div className='amount'>{ dynamicRange[0] }</div>
                    <Range min={MIN_DYNAMIC_RANGE} max={MAX_DYNAMIC_RANGE} allowCross={false} value={dynamicRange} onChange={value => setDynamicRange(value)} />
                    <div className='amount'>{ dynamicRange[1] }</div>
                  </div>
                  
                  { !drumsMode && (
                    <>
                    <div className='label'>Scale</div>
                      <Form.Control as='select' className='option scale' value={scale} onChange={e => changeScale(e.target.value)}>
                        { Object.entries(COMPOSER_SCALES).map(([key, scale]) => (
                          <option key={key} value={key}>{ scale.phrase }</option>
                        ))}
                      </Form.Control>
                    </>
                  )}

                  <div className='option over-the-bar'>
                    <Checkbox checked={overTheBar} onChange={e => setOverTheBar(e.target.checked)} togglePosition='end'>Over the bar</Checkbox>
                  </div>
                </div>
              </div>
            </div>

            <div className='tempo-swing'>
              <div role='button' className='playback-toggle' onClick={toggleSequence}>
                { isPlaying ? <div className='stop-icon' /> : <PlayIconV2 /> }
              </div>

              <div><Translate>Tempo</Translate></div>

              <div className='slider-container'>
                <Slider value={qpm} max={220} min={30} onChange={value => setQpm(value)} />
                <div className='number'>{ qpm }</div>
              </div>

              <div><Translate>Swing</Translate></div>
              
              <div className='slider-container'>
                <Slider value={swing} max={75} min={50} onChange={value => setSwing(value)} />
                <div className='number'>{ swing }</div>
              </div>
            </div>
          </div>
        </div>

        <div className='velocity-editor'>

          <div className='velocity-probability'>
            <div className='velocity-slider'>
              <p><strong><Translate>Velocity</Translate></strong></p>
              <div>{ tempVelocity }</div>
              <Slider value={tempVelocity} vertical min={0} max={127} onChange={value => setTempVelocity(value)} onAfterChange={onAfterVelocityChange} />
            </div>

            <div className='probability'>
              <div className='label'><Translate>Probability</Translate></div>
              <div className='probability-slider'>
                <Slider value={tempProbability} min={1} max={100} onChange={value => setTempProbability(value)} onAfterChange={onAfterProbabilityChange} />
                <div className='amount'>{ tempProbability }</div>
              </div>
            </div>
          </div>

          <div className='editor'>
            <div className='roll-controls'>
              <Form.Label>Subdivision</Form.Label>
              <Form.Control as='select' className='sub-division' value={division} onChange={e => setDivision(parseInt(e.target.value))}>
                { DIVISIONS.map(div => (
                  <option key={div} value={div}>{ div === 6 ? 3 : div }</option>
                  ))}
              </Form.Control>

              { !drumsMode && (
                <>
                  <div className='note-chord-checkbox'>
                    <div><Translate>Single Notes</Translate></div>
                    <Checkbox value={addChord} onChange={e => setAddChord(e.target.checked)} />
                    <div><Translate>Chords</Translate></div>
                  </div>

                  <Form.Control className='program-select' as='select' value={program} onChange={e => setProgram(parseInt(e.target.value, 10)) }>
                    { availablePrograms().filter(program => program.number < 128 && (!localSoundfonts || program.number < 1)).map(program => (
                      <option key={program.number} value={program.number}>{ program.name }</option>
                    ))}
                  </Form.Control>
                </>
              )}
            </div>

            <PianoRoll drumsMode={drumsMode} playNote={playNote} scale={COMPOSER_SCALES[scale].full} root={root} division={division} duration={duration} swing={swing} addChord={addChord} noteVelocity={noteVelocity} noteProbability={noteProbability} notesToSequence={notesToSequence} onNoteSelect={onNoteSelect} pentatonicScale={pentatonic && COMPOSER_SCALES[scale].pentatonic} notes={notes} chords={chords} setNotes={setNotes} setChords={setChords} playingNotes={playingNotes} toggleSequence={toggleSequence} sequenceId={sequenceId} />

            <div className='overflow-margin-bottom' />
          </div>
        </div>
      </div>

      <SelectStyleModal show={showStyleSelect} onHide={() => setShowStyleSelect(false)} onSubmit={onSubmitStyles} styles={styles} />
      <CropNoteModal show={showCropNotes} onHide={onHideCropNotes} onSubmit={onSubmitCropNotes} />
      <SaveSequenceModal show={showSaveSequence} onHide={() => setShowSaveSequence(false)} onSubmit={() => saveSequence(true)} />

      <Toast show={toastSequenceSaved} autohide delay={2000} className='strofe-toast' onClose={() => setToastSequenceSaved(false)}>
        <Toast.Body><Translate>Sequence saved succesfully</Translate></Toast.Body>
      </Toast>
    </div>
  )
}
