// As English is the development locale, any phrase that has the same { [key]: value }
// will be added to this array. On different locales, these must exists in the translations file:

const exactTranslations = [
  'Get started',
  'Login',
  'Cancel',
  'Strofe',
  'Loading',
  'Loading...',
  'Edit',
  'Continue',
  'Back',
  'Make Your Own Music In Seconds',
  
  // Login Modal
  // -----------
  'Email address',
  'Password',
  'Forgot password?',
  'Log In to Strofe',
  'Continue with Facebook',
  'Already have an account? Log In',

  // Navigation
  'Library',
  'Create',
  'Transaction History',
  'Get Strofe Coins',
  'Strofe Coins',
  'Support Center',
  'User Stats',
  'Release Version',
  'Print Audio Log',
  'Coupon Manager',
  'Strofe Ambassador',

  // Music
  // -----
  'Spooky',
  'Sad',
  'Chill',
  'Happy',
  'Spirited',
  'Select a mood for your track',
  'Music Style',
  'Auto',
  'Customize',
  'Jazz',
  'Bossa Nova',
  'Reggae',
  'Orchestral Pop',
  'Retro Futuristic',
  'Lofi',
  'Pop',
  'Epic',
  'Hip Hop',
  'Retro Futuristic',
  'Oddity',
  'Anime',
  'Latin',
  'Create',
  'Your new, unique track will be ready in seconds...',
  'Instrument Pack',
  'Automatic',
  'Add instrument',
  'Alto',
  'Tenor',
  'Secondary Melody',

  "Questions or feedback?",
  "Let us know!",
  'Logout',

  // Editor
  'Make Public',
  'Regenerate instrument',
  'Regenerate Track',
  'Please wait...',
  'Regenerating Track',
  'Solo',
  'Regenerating...',
  'Creating Instrument...',
  'Download',
  'Loading Track...',
  'Reroll',
  'Reroll Track',
  "Let's try again!",
  'Custom Loop',
  'Select a loop',
  'Remove Custom Loop',
  'Select category',
  "Select the loop's category",
  'Are you sure you will like to delete this track?',

  // Library
  'Title',
  'Modified',
  'Style',
  'Mood',
  'Edit Track',
  'Share',
  'Duplicate',
  'Track duplicated',
  'Loading Tracks...',
  'Filter',
  'All',
  'Genre',
  'Favorites',
  'Custom Filter',
  'Purchased',
  'Showing purchased tracks only. <1>Remove filter.</1>',
  'Showing favorite tracks only. <1>Remove filter.</1>',
  'Showing filtered results. <1>Remove filter.</1>',
  'Date modified (newest first)',
  'Date modified (oldest first)',
  'Title (ascending)',
  'Title (descending)',
  'Track duration (shortest first)',
  'Track duration (longest first)',
  'Your library is empty.',
  'There are no tracks that match the current filter.',
  'Copy Link',

  // Feedback form
  'Submit a bug or feedback',
  'Feedback Type',
  'How can we help?',
  'Description',
  'Submit',
  'Provide us with a description',
  'Close',

  'Terms',
  'Privacy Policy',

  'Delete Track',
  'Delete',
  'Music track deleted',

  // Pricing
  'Purchase',
  'Download Track',
  'Purchase Track',
  'Your track is ready for download.',
  'Hang on tight!',
  'Your song is being processed, this will take a few seconds.',
  'Generating...',
  'Download MIDI',
  'Download MP3',
  'Download WAV',
  'Purchase Track (MP3)',
  'Purchase Track (MP3 + MIDI)',
  'Add MIDI format',
  'Current Balance',
  'Final Balance',
  'Get Track',
  'Congrats on your new track!',
  'Track (MIDI)',
  'Processing...',
  'Welcome To Strofe Premium',
  'Own this track forever, use it however you want!',
  'Create unlimited tracks, sign up for a free 7-day trial.',
  '$10 / month',
  'Become a Strofe Premium Member',
  'Create unlimited tracks',
  "Be the first to know about Strofe's updates and improvements",
  'Subscribe Now',
  'Pick up to 300 songs every month to own forever',
  'Start Free Trial',
  'Tell Me More',
  '$10 / month with a 7 Day Free Trial',
  'Pricing',
  'Standard Monthly',
  'Pro Monthly',
  'Standard Anually',
  'Pro Annually',
  'Annually',
  'Monthly',
  'Current Plan',
  'Select',
  'Reload',
  '/ mo',
  '/ year',
  'yearly',
  'monthly',
  'month',
  'year',
  'MIDI',
  'MP3',
  'Select Format',
  'Add MIDI Format',
  'Start Trial',

  // Settings
  // -------
  'Settings',
  'Profile',
  'Change password',
  'Email',
  'Display name',
  'Verify Your Email',
  'Sending verification code...',
  'Verify',
  'Verifying...',
  'Re-send Verification Code',
  'Current password',
  'Confirm your password',
  'New password',
  'Confirm new password',
  'Update',
  'New email address',
  'Your password has been updated',
  'Your email address has been updated',
  'Your email is verified',
  'Updating profile...',
  'Language',
  'Your display name has been updated',
  'Click here to verify your email',
  'Update Password',
  'Update Email',
  'Update Display Name',
  "You're a Strofe Ambassador!",
  "Delete Your Account",
  'Delete Account',
  'Verify',
  'Your account has been permanently deleted.',
  'Back to Strofe',
  'Unsubscribe from Strofe',
  'Hope to see you again soon!',
  'Change Payment Method',
  "You are a Strofe Premium Member",
  "You are a Strofe Member",
  "Cancel Your Membership",
  "Your trial ends today.",

  // Coin Transactions
  // -------
  'Song Creation',
  'Total',
  'Strofe Coin Transactions',
  'Date',
  'Transaction',
  'Amount',
  'Song Purchase',
  'Email Verification',
  'Sign Up',
  'Loading Transactions...',
  'Coupon Redemption',
  'Coin Purchase',
  'Song Like',
  'A user joined Strofe using your invite link',
  'Past Day',
  'Past 7 Days',
  'Past 30 Days',
  'Past Year',
  'Credits',
  'Debits',

  // Get Coins
  // ---------
  'What are Strofe Coins?',
  'Create an account',
  'Verify your email',
  'Make a song every day!',
  'Payment Successful',
  'Thank you for your purchase!',
  'Previous Balance',
  "Today's Purchase",
  'Set your display name',
  'Link copied to clipboard',
  'Help grow the community by inviting users to Strofe',
  'Sale',
  'Day',
  'Days',
  'Hour',
  'Hours',
  'Minute',
  'Minutes',
  'Seconds',
  '50% Off Strofe Coins',
  'Summer Sale',
  'Own Your Music',
  'Purchase Strofe Coins for Half our Regular Price',
  'Offer ends May 31st.',
  'Winter Sale',
  'Sale 50% Off',
  'Or pay with PayPal',
  'Buy Strofe Coins',
  '<0>After you acquire a track, its copyright and distribution rights are yours, forever. <1>Learn more.</1></0>',
  '50% Off All MAY',
  'Purchase Strofe Coins for Half our Regular Price.',
  'Try out the new Anime Style!',
  'Animay Sale',
  'Try the New Anime Style',
  'What are the benefits of Strofe Premium Membership?',

  // Coupons
  // -------
  'Redeem Coupon',
  'You already redeemed this coupon',
  'Coupon Redeemed',
  'Coupon Redemption',
  'Expired',
  'Oops!',
  'Your coupon has been redeemed!',
  'Refresh',

  // Comments
  // --------
  'Write a comment...',
  'Comment',
  'Report',
  'Reply',
  'View more replies',
  'Comments are disabled on this track',
  'Delete Comment',

  // Loop Creator (Composer)
  'Tempo',
  'Swing',
  'Single Notes',
  'Accompaniment',
  'Chords',
  'Velocity',
  'Probability',
  'Drums',
  'Bass',
  'Bass Line',
  'Ready',
  'Draft',
  'Review',
  'Published',
  'bass',
  'chords',
  'drums',
  'Note',
  'Status',
  'Category',
  'Created By Me',
  'Styles',
  'Creator',
  'Loading Loops...',
  'Save',
  'Create New Loop',
  'Loop saved succesfully',
  'Loop Duration Change',
  'Loop Status Change',
  'Confirm',
  'Create Loop',
  "Select your new loop's category",
  '#',
  'Your loop library is empty.',
  'Edit Sequence',
  'New Composition',
  'Loops',
  'Sequence saved succesfully',
  'Select a loop',

  // Instrument Select
  'Select',
  'Keys',
  'Strings',
  'Winds',
  'SFX',
  'Brass',
  'Guitars',
  'Percussion',
  'Search...',
];

export const getExactTranslations = () => {
  const translations = {};
  exactTranslations.forEach(entry => translations[entry] = entry);

  return translations;
}
