import { useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { usersActions, usersSelectors } from '../../store/usersSlice';
import { Trans as Translate, useTranslation } from 'react-i18next';
import { strofeApi } from '../../api/strofeApi';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { PRICING_TIER } from '../../utils/Prices';

import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Toast from "react-bootstrap/Toast";
import Form from "react-bootstrap/Form";

import NavigationHeader from "../NavigationHeader";
import VerifyEmailModal from './VerifyEmailModal';
import ErrorModal from '../../modals/Error/ErrorModal';
import UpdateProfileModal from './UpdateProfileModal';
import DeleteAccountModal from './DeleteAccountModal';
import FeedbackModal from '../../modals/Feedback/FeedbackModal';

import GearIcon from "../../icons/GearIcon";
import PencilIcon from "../../icons/PencilIcon";
import AmbassadorIcon from '../../icons/AmbassadorIcon';
import MoodSadIcon from '../../icons/MoodSadIcon';
import MemberAvatar from '../../icons/art/MemberAvatar';

import './Settings.scss';

const LANGUAGES = {
  en: 'English',
  es: 'Español'
};

const UPDATE_TOASTS = {
  password      : 'Your password has been updated',
  email         : 'Your email address has been updated',
  emailVerified : 'Your email is verified',
  display_name  : 'Your display name has been updated',
}

export default function Settings() {

  const [deletingSubscription, setDeletingSubscription] = useState(false);
  const [showError, setShowError] = useState(false);
  const [showVerify, setShowVerify] = useState(false);
  const [showDeleteAccount, setShowDeleteAccount] = useState(false);
  const [showFeedback, setShowFeedback] = useState(false);
  
  const [showUpdateProfile, setShowUpdateProfile] = useState(false);
  const [updateField, setUpdateField] = useState(false);
  const [showUpdateToast, setShowUpdateToast] = useState(false);

  const [showUnsubscribe, setShowUnsubscribe] = useState(false);
  const [inputUnsubscribe, setInputUnsubscribe] = useState('');
  const [showCancelled, setShowCancelled] = useState(false);
  
  const { i18n } = useTranslation();
  const dispatch = useDispatch();
  const currentUser = useSelector(usersSelectors.getCurrentUser);
  const subscription = currentUser?.subscription;

  const nextPayment = useMemo(() => {
    if (subscription) {
      return DateTime.fromISO(subscription?.next_payment_due);
    }
  }, [subscription]);

  const trialEnds = useMemo(() => {
    if (subscription?.trial_end) {
      const trialEndDate = DateTime.fromISO(subscription?.trial_end);
      const dateDifference = trialEndDate.diffNow('days');
      return dateDifference.days;
    }
    else {
      return null;
    }
  }, [subscription]);

  useEffect(() => {
    if (showUnsubscribe) {
      setInputUnsubscribe('');
    }
  }, [showUnsubscribe]);

  const deleteSubscription = async () => {

    setDeletingSubscription(true);

    try {
      await strofeApi.delete('/subscriptions/' + currentUser.id);
      await dispatch(usersActions.fetchById({ id: currentUser.id }));

      setDeletingSubscription(false);
      setShowUnsubscribe(false);
      setShowCancelled(true);
    }
    catch {
      setShowError(true);
    }
  }

  const handleVerify = () => {
    // set the field as 'emailVerified' in order to show toast on success:
    setShowUpdateToast(false);
    setUpdateField('emailVerified');
    setShowVerify(true);
  }

  const onUpdateField = field => {
    setShowUpdateToast(false);
    setUpdateField(field);
    setShowUpdateProfile(true);
  }

  const onFieldUpdateSucess = () => {
    setTimeout(() => setShowUpdateToast(true), 1000);
  }

  // TODO -> all languages are loaded, only the required language should be loaded using conditional imports
  const updateLanguage = e => {
    const user = {
      language : e.target.value
    };
    dispatch(usersActions.updateFields({ user }));
    i18n.changeLanguage(e.target.value);
  }

  const subscriptionActive = currentUser.subscribed && ['active', 'trialing'].includes(subscription?.status);
  const canUnsubscribe = inputUnsubscribe.toLowerCase().trim() === 'unsubscribe' || inputUnsubscribe.toLowerCase().trim() === '"unsubscribe"';

  let planType = 'Premium';

  subscription && Object.entries(PRICING_TIER).find(([key, tier]) => {
    if (tier.id === subscription.price_id) {
      planType = key.includes('std') ? 'Standard' : 'Pro';
      return true;
    }

    return false;
  });

  return (
    <div className='__settings'>
      <NavigationHeader />

      <div className='header'>
        <GearIcon width={36} height={36} /><Translate>Settings</Translate>
      </div>

      { currentUser.ambassador && (
        <div className='user-role'>
          <AmbassadorIcon className='icon' />
          {/* <Translate */}
          <div className='text'>
            <h3><Translate>You're a Strofe Ambassador!</Translate></h3>
            <p><Translate i18nKey='ambassador-detail' /></p>
          </div>
        </div>
      )}

      { currentUser.subscribed && (
        <div className='overflow-container'>
          <div className='premium-member-container'>
            <div className='premium-member'>
              <MemberAvatar className='avatar' />
              <div className='text'>
                <h3>
                  { planType === 'premium'
                  ? <Translate>You are a Strofe Premium Member</Translate>
                  : <Translate>You are a Strofe Member</Translate>
                  }
                </h3>
                <div className='information'>
                  { subscription?.status === 'trialing' && (
                    <p className='days-left'>
                      { trialEnds >= 1
                      ? <Translate i18nKey='trial-x-days-left' values={{ count: Math.ceil(trialEnds) }} />
                      : <Translate>Your trial ends today.</Translate>
                      }
                    </p>
                  )}
                  <p className='details'>Status: Active</p>
                  { subscriptionActive
                  ? <p className='details'><Translate i18nKey='next-payment-date' values={{ date: nextPayment?.toLocaleString(DateTime.DATE_FULL) }} /></p>
                  : <p className='details'><Translate i18nKey='subscription-ends-date' values={{ date: nextPayment?.toLocaleString(DateTime.DATE_FULL) }} /></p>
                  }
                  { /*
                    <p className='details'>Payment Method: Visa (ends in 1234)</p>
                    <button><Translate>Change Payment Method</Translate></button>
                  */ }

                  {/* <p>Members can download and own 300 tracks every month. If you have any questions about your billing, <span role='button' onClick={() => setShowFeedback(true)}>let us know.</span></p> */}
                </div>
              </div>

              <div className='bottom-red-semicircle' />
              <div className='bottom-lime-semicircle-container'>
                <div className='bottom-lime-semicircle' />
              </div>

            </div>

            <div className='right-red-circle' />
            <div className='lime-inverted-circle'>
              <div className='inner-circle' />
            </div>
            <div className='circle-small orange-circle' />
            <div className='circle-small green-circle' />
          </div>
        </div>
      )}
      <div className='settings-selection'>
        <div className='entry'>
          <div className='field'><Translate>Display name</Translate></div>
          <div className='input'>
            <Form.Control disabled value={currentUser.display_name} className='col' />
            <PencilIcon role='button' onClick={() => onUpdateField('display_name')} />
          </div>
        </div>

        { (currentUser?.provider === null || currentUser?.provider != 'roblox') &&
          <div className='entry'>
            <div className='field'><Translate>Email</Translate></div>
            <div className='input'>
              <Form.Control disabled value={ currentUser.email } />
              <PencilIcon role='button' onClick={() => onUpdateField('email')} />
            </div>
            <div className='status-container'>
            { currentUser.email_verified
            ? <div className='status success'><Translate>Your email is verified</Translate></div>
            : (
              <div className='status failure'>
                <span className='verify-email-prompt' onClick={handleVerify}><Translate>Click here to verify your email</Translate></span>
              </div>
            )}
            </div>
          </div>
        }

        <div className='change-password'>
          <Button onClick={() => onUpdateField('password')}>
            <Translate>Change password</Translate>
          </Button>
        </div>

        <div className='entry'>
          <div className='field'><Translate>Language</Translate></div>
          <Form.Control className='language-input' as='select' value={i18n.language} onChange={updateLanguage}>
            { Object.entries(LANGUAGES).map(([id, language]) => (
              <option key={id} value={id}>{ language }</option>
            ))}
          </Form.Control>
        </div>

        <div className='cancel-options'>
          <div role='button' onClick={() => setShowDeleteAccount(true)}><Translate>Delete Your Account</Translate></div>
          { subscriptionActive && <> | <div role='button' onClick={() => setShowUnsubscribe(true)}><Translate>Cancel Your Membership</Translate></div></>}
        </div>

        <div className='bottom-links'>
          <Link to='/privacy'><Translate>Privacy Policy</Translate></Link>
          <Link to='/terms'><Translate>Terms</Translate></Link>
        </div>
      </div>

      <Modal show={showUnsubscribe} className='__modal' backdrop='static' onHide={() => !deletingSubscription && setShowUnsubscribe(false)} size='sm'>
        <Modal.Header>
          <Translate>Unsubscribe from Strofe</Translate>
        </Modal.Header>

        <Modal.Body>
          <Translate i18nKey='cancel-subscription' />
          <Form.Control value={inputUnsubscribe} onChange={e => setInputUnsubscribe(e.target.value)} />
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" disabled={deletingSubscription} onClick={() => setShowUnsubscribe(false)}>Close</Button>
          <Button variant="primary" disabled={deletingSubscription || !canUnsubscribe} onClick={deleteSubscription}>Unsubscribe</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showCancelled} className='__modal __cancelled-modal' backdrop='static' onHide={() => setShowCancelled(false)}>
        <Modal.Header>
          <Translate>Hope to see you again soon!</Translate>
        </Modal.Header>

        <Modal.Body>
          <div className='content'>
            <MoodSadIcon className='sad-icon' />
            <div><Translate i18nKey='become-member-future' /></div>
          </div>
        </Modal.Body>

        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowCancelled(false)}>Close</Button>
        </Modal.Footer>
      </Modal>

      <ErrorModal show={showError} />
      
      <VerifyEmailModal show={showVerify} onHide={() => setShowVerify(false)} onUpdateSuccess={onFieldUpdateSucess} />
      <UpdateProfileModal show={showUpdateProfile} field={updateField} onHide={() => setShowUpdateProfile(false)} onUpdateSuccess={onFieldUpdateSucess} />
      <DeleteAccountModal show={showDeleteAccount} onHide={() => setShowDeleteAccount(false)} currentUser={currentUser} />
      <FeedbackModal show={showFeedback} onClose={() => setShowFeedback(false)} />

      <Toast show={showUpdateToast} className='strofe-toast' autohide delay={2000} onClose={() => setShowUpdateToast(false)}>
        <Toast.Body><Translate>{ UPDATE_TOASTS[updateField] }</Translate></Toast.Body>
      </Toast>
    </div>
  );
}
