import { Trans as Translate } from 'react-i18next';
import { GoogleLogin } from 'react-google-login';
import Button from 'react-bootstrap/Button';
import { useEffect } from 'react';

import fLogo from '../images/f_logo_white_250.png';
import discordLogo from '../images/discord_logo_white.png';
import RobloxIcon from "../icons/RobloxIcon";
import './SSOButtons.scss';
import useEventCallback from '../hooks/useEventCallback';

import { UserManager } from 'oidc-client-ts';

const roblox_config = {
  authority: "https://apis.roblox.com",
  client_id: process.env.REACT_APP_ROBLOX_CLIENT_ID,
  redirect_uri: "http://localhost:3000/auth/roblox/callback",
  response_type: "code",
  scope: "openid profile",
  filterProtocolClaims: true,
  loadUserInfo: true,
};

export default function SSOButtons({ onClickFacebook, onSuccessGoogle, onFailureGoogle, onSuccessDiscord, onSuccessRoblox, showSeparator = true }) {
  return (
    <div className='__sso'>
      <FacebookSSOButton onClick={onClickFacebook} />
      <GoogleSSOButton onSuccess={onSuccessGoogle} onFailure={onFailureGoogle} />
      <DiscordSSOButton onSuccess={onSuccessDiscord} />
      <RobloxSSOButton onSuccess={onSuccessRoblox} />
      { showSeparator && <SSOSeparator />}
    </div>
  );
}

export function SSOSeparator() {
  return (
    <div className="__sso-separator">
      <div className="__sso-separator-lines" />
      <div className="__sso-separator-or">OR</div>
      <div className="__sso-separator-lines" />
    </div>
  );
}

export function FacebookSSOButton({ onClick }) {
  return (
    <Button variant="primary" onClick={onClick} className='__sso-btn __sso-btn-facebook'>
      <div className='btn-inner'>
        <span className='logo'><img src={fLogo} alt='f' /></span>
        <span className='text'><Translate>Continue with Facebook</Translate></span>
      </div>
    </Button>
  );
}

function ROBLOX_LINK() {
  const redirectUri = encodeURIComponent(window.location.origin + '/auth/roblox/callback');
  const clientId = process.env.REACT_APP_ROBLOX_CLIENT_ID;

  return `https://apis.roblox.com/oauth/v1/authorize?client_id=${process.env.REACT_APP_ROBLOX_CLIENT_ID}&redirect_uri=${redirectUri}&scope=openid%20profile&response_type=code&state=abc123`;
  // return `https://apis.roblox.com/oauth/v1/authorize?client_id=${process.env.REACT_APP_ROBLOX_CLIENT_ID}&code_challenge=PLEKKVCjdD1V_07wOKlAm7P02NC-LZ_1hQfdu5XSXEI&code_challenge_method=S256&redirect_uri=${redirectUri}&scope=openid%20profile&response_type=code&state=abc123`;
}

export function RobloxSSOButton({ onSuccess }) {
  const onStorageChange = useEventCallback(event => {
    const { key, oldValue, newValue } = event;

    if (key === 'roblox_code' && oldValue !== newValue) {
      onSuccess(newValue);
      window.removeEventListener('storage', onStorageChange);
      localStorage.removeItem('roblox_code');
    }
    else if (key === 'discord_error' && oldValue !== newValue) {
      window.removeEventListener('storage', onStorageChange);
      localStorage.removeItem('roblox_error');
    }
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('storage', onStorageChange);
      localStorage.removeItem('roblox_code');
      localStorage.removeItem('roblox_error');
    }
  }, [onStorageChange]);

  const onClick = () => {
    PopupCenter(ROBLOX_LINK(), 'Sign in with Roblox', 920, 720);
    window.addEventListener("storage", onStorageChange);
  }

  return (
    <Button className='__sso-btn __sso-btn-roblox' onClick={onClick}>
      <div className='btn-inner'>
        <span className='logo'><RobloxIcon /></span>
        <span className='text'>Continue with Roblox</span>
      </div>
    </Button>
  );
}

export function GoogleSSOButton({ onSuccess, onFailure }) {
  return (
    <GoogleLogin
      clientId={ process.env.REACT_APP_GOOGLE_CLIENT_ID }
      buttonText='Continue with Google'
      onSuccess={onSuccess}
      onFailure={onFailure}
      cookiePolicy='single_host_origin'
      responseType='code'
      className='__sso-google-btn'
      style={{ borderRadius: 4, color: 'red' }}
    />
  );
}

function DISCORD_LINK() {
  const redirectUri = encodeURIComponent(window.location.origin + '/auth/discord/callback');
  const clientId = process.env.REACT_APP_DISCORD_CLIENT_ID;

  return `https://discord.com/api/oauth2/authorize?client_id=${clientId}&redirect_uri=${redirectUri}&response_type=code&scope=email%20identify`;
}

// TODO -> use Broadcast Channel API when Safari supports it:
export function DiscordSSOButton({ onSuccess }) {
  const onStorageChange = useEventCallback(event => {
    const { key, oldValue, newValue } = event;

    if (key === 'discord_code' && oldValue !== newValue) {
      onSuccess(newValue);
      window.removeEventListener('storage', onStorageChange);
      localStorage.removeItem('discord_code');
    }
    else if (key === 'discord_error' && oldValue !== newValue) {
      window.removeEventListener('storage', onStorageChange);
      localStorage.removeItem('discord_error');
    }
  });

  useEffect(() => {
    return () => {
      window.removeEventListener('storage', onStorageChange);
      localStorage.removeItem('discord_code');
      localStorage.removeItem('discord_error');
    }
  }, [onStorageChange]);

  const onClick = () => {
    PopupCenter(DISCORD_LINK(), 'Sign in with Discord', 920, 720);
    window.addEventListener("storage", onStorageChange);
  }

  return (
    <Button className='__sso-btn __sso-btn-discord' onClick={onClick}>
      <div className='btn-inner'>
        <span className='logo'><img src={discordLogo} alt='discord' /></span>
        <span className='text'>Continue with Discord</span>
      </div>
    </Button>
  );
}

// Source: Facebook SSO's code:
function PopupCenter(url, title, w, h) {
  var userAgent = navigator.userAgent,
      mobile = function() {
        return /\b(iPhone|iP[ao]d)/.test(userAgent) ||
          /\b(iP[ao]d)/.test(userAgent) ||
          /Android/i.test(userAgent) ||
          /Mobile/i.test(userAgent);
      },
      screenX = typeof window.screenX != 'undefined' ? window.screenX : window.screenLeft,
      screenY = typeof window.screenY != 'undefined' ? window.screenY : window.screenTop,
      outerWidth = typeof window.outerWidth != 'undefined' ? window.outerWidth : document.documentElement.clientWidth,
      outerHeight = typeof window.outerHeight != 'undefined' ? window.outerHeight : document.documentElement.clientHeight - 22,
      targetWidth = mobile() ? null : w,
      targetHeight = mobile() ? null : h,
      V = screenX < 0 ? window.screen.width + screenX : screenX,
      left = parseInt(V + (outerWidth - targetWidth) / 2, 10),
      right = parseInt(screenY + (outerHeight - targetHeight) / 2.5, 10),
      features = [];
  if (targetWidth !== null) {
    features.push('width=' + targetWidth);
  }
  if (targetHeight !== null) {
    features.push('height=' + targetHeight);
  }
  features.push('left=' + left);
  features.push('top=' + right);
  features.push('scrollbars=1');

  var newWindow = window.open(url, title, features.join(','));

  if (window.focus) {
    newWindow.focus();
  }

  return newWindow;
}
